import React from "react";
import styles from "./ABOGrowthCompanion.module.scss";
// import KeyKpis from "./KeyKPIs/KeyKpis";
import LegAnalysis from "./LegAnalysis/LegAnalysis";
// import ProbabilityModeling from "./ProbabilityModeling/ProbabilityModeling";
// import Compensation from "./Compensation/Compensation";
import useScroll from "../../../redux/slices/useScrollSlice";
import BusinessPerformance from "./BusinessPerformance/BusinessPerformance";
import AGCDetailsTile from "../../common/AGCDetailsTile/AGCTile";
import Pipeline from "./Pipeline/Pipeline";
import Qualifications from "./Qualifications/Qualifications";
import Income from "./Income/Income";

interface ABOGrowthCompanionProps {
  activeLink: string;
  refMap: Record<string, React.RefObject<HTMLDivElement>>;
  onSectionChange: (sectionId: string) => void;
}

const ABOGrowthCompanion: React.FC<ABOGrowthCompanionProps> = ({
  activeLink,
  refMap,
  onSectionChange,
}) => {
  const { handleClick } = useScroll(refMap, onSectionChange);
  return (
    <div
      className={styles.abo_growth_companion_wrapper}
      data-testid="business_information_plan_testID"
    >
      <div
        className={`content_inner ${styles.abo_growth_companion_inner_wrapper}`}
      >
        {/* <div className={styles.section_wrapper} ref={refMap["key_kpis"]}>
          <KeyKpis />
        </div> */}
        <div
          className={styles.section_wrapper}
          ref={refMap["business_performance"]}
        > 
          <BusinessPerformance />
        </div>
        <div className={styles.section_wrapper} ref={refMap["pipeline"]}>
          <Pipeline category= "pipeline" />
        </div>
        <div className={styles.section_wrapper} ref={refMap["qualifications"]}>
          <Qualifications  category="qualifications"/>
        </div>
        <div className={styles.section_wrapper} ref={refMap["structure"]}>
          <LegAnalysis  category="structure"/>
        </div>
        <div className={styles.section_wrapper} ref={refMap["income"]}>
          <Income category="income" /> 
         </div>
      </div>
    </div>
  );
};

export default ABOGrowthCompanion;
