import React from "react";
import styles from "./Benchmark.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import { CurrencyFormatter } from "../../../utils/currencyFormatter";

type BenchmarkProps = {
  minValue: number | 0;
  maxValue: number | 0;
  midValue?: number | 0;
  benchmarkValue: number | 0;
  isPercentValue: boolean | undefined;
  benchmarkDataType?: string;
  currency?: string;
};

const Benchmark: React.FC<BenchmarkProps> = ({
  minValue = 0,
  maxValue = 0,
  midValue = 0,
  benchmarkValue = 0,
  isPercentValue = false,
  benchmarkDataType = "",
  currency = "",
}) => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const middleValue = (minValue + maxValue) / 2;
  const benchmarkPercentValue = (benchmarkValue * 100) / maxValue;

  return (
    <div className={`benchmark_wrapper ${styles.benchmark_wrapper}`}>
      <div
        className={`benchmark_title_section ${styles.benchmark_title_section}`}
      >
        <span>{t("benchmark")}</span>
        <Tooltip
          content={
            <>
              {t("tooltip.0")}
              <span className={`bold_text ${styles.bold_text}`}>
                {" "}
                {t("tooltip.1")}{" "}
              </span>
              {t("tooltip.2")}
            </>
          }
          width="257"
          height="120px"
          top="-640%"
          left="610%"
        >
          <span>
            <Icon
              name="info_icon"
              size={18}
              color={currentTheme === "light" ? "#707070" : "#AEAEB2"}
            />
          </span>
        </Tooltip>
      </div>
      <div className={`benchmark ${styles.benchmark}`}>
        <div className={`benchmark_track ${styles.benchmark_track}`}>
          <div className={`benchmark_sections ${styles.benchmark_sections}`}>
            {/* Three sections with a middle solid line */}
            <div
              className={`benchmark_section ${styles.benchmark_section}`}
            ></div>
            <div
              className={`benchmark_section middle ${styles.benchmark_section} ${styles.middle}`}
            ></div>
            <div
              className={`benchmark_section ${styles.benchmark_section}`}
            ></div>
            <div
              className={`benchmark_section ${styles.benchmark_section}`}
            ></div>
          </div>

          {/* Dynamic marker (map pin) */}
          <div
            className={`${styles.benchmark_marker}`}
            style={{ left: `${benchmarkPercentValue}%` }}
          >
            <Icon
              name="benchmark_marker"
              size={21}
              color={currentTheme === "light" ? "#5969D6" : "#99B2F5"}
            />
          </div>
        </div>

        <div className={`${styles.benchmark_labels}`}>
          <span>
            {benchmarkDataType === "currency" && minValue !== 0 && currency}
            {CurrencyFormatter("USD", minValue)}
            {benchmarkDataType === "percent" ? "%" : ""}
          </span>
          <span>
            {benchmarkDataType === "currency" && currency}
            {CurrencyFormatter("USD", midValue ? midValue : middleValue)}
            {benchmarkDataType === "percent" ? "%" : ""}
          </span>
          <span>
            {benchmarkDataType === "currency" && currency}
            {CurrencyFormatter("USD", maxValue)}
            {benchmarkDataType === "percent" ? "%" : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Benchmark;
