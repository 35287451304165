import React, { useEffect, useState, useCallback } from "react";
import styles from "./APNotesSearchBar.module.scss";
import SearchLight from "../../../../../../assets/images/seach_icon_l.svg";
import SearchDark from "../../../../../../assets/images/search_icon_d.svg";
import { useSelector } from "react-redux";
import { RootState, DataItem } from "../../../../../../types/types";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Icon from "../../../../../common/Icon/Icon";

interface SearchBarProps {
  placeholder: string;
  searchAPNotes: (args:string) => {}
}

const APNotesSearchBar: React.FC<SearchBarProps> = ({ placeholder, searchAPNotes }) => {
  const history = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState<DataItem[]>([]);
  const currentTheme = useSelector((state: RootState) => state.theme.currentTheme);
  const { t } = useTranslation();
  const searchIcon = currentTheme === "light" ? SearchLight : SearchDark;

  const searchDebounceAPNotes = 
    debounce(async (term) => {
      if (term.trim().length > 2) {
        searchAPNotes(term);
      }else if(term.trim().length === 0) searchAPNotes(term);
    }, 600);

  const handleSearchABO = async (e:any) => {
    const term = e.target.value;
    setSearchTerm(term);
    searchDebounceAPNotes(term);
  };
  const handleSearchInput = async () => {
    searchDebounceAPNotes(searchTerm);

	// const searchClick={
	// 	"click_section":"ap_notes",
	// 	"click_category":"header",
	// 	"click_action" : `button<search>`
	// 	}
	
	// 	window?.heap?.track(searchClick);
  };

  useEffect(() => {
    setSearchTerm("");
  }, [navigate]);

  return (
    <div className={`search_bar_container ${styles.search_bar_container}`}>
      <Icon
          name="search"
          size={24}
          color={currentTheme === 'light' ? '#2c2c2c' : '#AEAEB2'}
          onClick={handleSearchInput}
          className={styles.search_icon}
        />
      <input
        className={`search_input ${styles.search_input}`}
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(event) => handleSearchABO(event)}
      />
    </div>
  );
};

export default APNotesSearchBar;
