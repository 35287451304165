import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProgressNotes.module.scss";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import Button from "../../../common/Button/Button";
import { useAppSelector } from "../../../../redux/hooks";
import sortIconLight from "../../../../assets/images/LightTheme/up_down.svg";
import sortIconDark from "../../../../assets/images/DarkTheme/up_down_dark.svg";
import FormatterBar from "../../../common/FormatterBar/FormatterBar";
import {
  useGetProgressNotesListQuery,
  useCreateProgressNotesMutation,
  useEditProgressNotesMutation,
  useDeleteProgressNoteMutation,
  useGetUsersListByNativeIDsQuery,
} from "../../../../redux/services/progressNotesAPI";
import { RootState } from "../../../../redux/store";
import HTMLtoText from "../../../common/HTMLToText/HTMLToText";
import { formatDate } from "../../../../utils/dateFormatter";
import {
  NOTES_SORT_LIST,
  countryCodeMappings,
} from "../../../../constants/dataConstants";
import ABOBackgroundLoader from "../../../common/Loaders/ABOBackgroundLoader/ABOBackgroundLoader";
import { getInitials } from "../../../../utils/common";
import ErrorInPageScreen from "../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../utils/validateAPIResponse";
import {
  getFullNameOfUserByNativeID,
  getUserNativeIDsArray,
} from "../../../../utils/progressNotesUtil";
import { UserDetails } from "../../../../types/types";
import useClickOutside from "../../../CustomHooks/useClickOutside";

interface ProgressNoteProps {
  onDeleteNote: (id: number) => void;
  onEditNote: (id: number) => void;
}

interface ProgressNote {
  aboNoteId: number;
  note: string;
  title: string;
  performanceYear: number;
  audit: {
    createdDate: string;
    createdBy: string;
    updatedDate: string;
    updatedBy: string;
  };
}

type FilterDataType = {
  id: string;
  name: string;
}

const ProgressNotes: React.FC<ProgressNoteProps> = ({
  onDeleteNote,
  onEditNote,
}) => {
  const { t } = useTranslation();
  const [progressNotes, setProgressNotes] = useState<ProgressNote[]>([]);
  const [userNativeIDs, setUserNativeIDs] = useState<string[]>([]);
  const [updatedNativeIDs, setUpdatedNativeIDs] = useState<string[]>([]);
  const [usersListByNativeId, setUsersListByNativeId] = useState<
    UserDetails[] | null
  >([]);
  const [textareaValue, setTextareaValue] = useState<string>("");
  const [isEditable, setIsEditable] = useState(false);
  const [isNoteEditable, setNoteEditable] = useState(false);
  const [saveEnable, setSaveEnable] = useState(false);
  const [editNoteId, setEditNoteId] = useState(null);
  const [sortByNewest, setSortByNewest] = useState(true);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const selectedYear = useAppSelector(
    (state: RootState) => state.selectedYear.selectedYear
  );
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [userAffiliateCode] = useState(userDetails?.userAffiliateCode || 0);
  const [isAPIError, setIsAPIError] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<FilterDataType>(NOTES_SORT_LIST?.[0]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const sortIcon = currentTheme === "light" ? sortIconLight : sortIconDark;
  const focusHandler = () => {
    setSaveEnable(false);
    setIsEditable(true);
    setNoteEditable(false);
  };
  const handleClickCancel = () => {
    setIsEditable(false);
    setNoteEditable(false);
  };

  //API call to get list of all notes
  const {
    isFetching: isNotesLoading,
    isError: isErrorInFetchNotes,
    data: notesData,
  } = useGetProgressNotesListQuery(
    {
      affiliateCode: userDetails.affiliateCode,
      aboNumber: userDetails.aboNumber,
      year: selectedYear,
    },
    { skip: isAPIError }
  );

  // Get the list of user details
  const { data: usersList } = useGetUsersListByNativeIDsQuery(
    {
      nativeIDs: userNativeIDs,
    },
    {
      skip: isEmpty(userNativeIDs) || userNativeIDs === updatedNativeIDs,
    }
  );

  useEffect(() => {
    setUsersListByNativeId(usersList?.body);
    if (!isEmpty(usersList)) {
      setUpdatedNativeIDs(userNativeIDs);
    }
  }, [usersList]);

  const sortDataByOrder = (sortByNewest: boolean) => {
    if (sortByNewest) {
      //Sort by newest date-time
      return [...notesData.body].sort(
        (a, b) =>
          new Date(b.audit.updatedDate).getTime() -
          new Date(a.audit.updatedDate).getTime()
      );
    } else {
      //Sort by oldest date-time
      return [...notesData.body].sort(
        (a, b) =>
          new Date(a.audit.updatedDate).getTime() -
          new Date(b.audit.updatedDate).getTime()
      );
    }
  };

  const handleEditTag = (updatedDate: string, createdDate: string) => {
    if (
      new Date(updatedDate)?.toLocaleString() ==
      new Date(createdDate)?.toLocaleString()
    ) {
      return "";
    } else {
      return t("edited");
    }
  };

  const handleAPIError = () => {
    setIsAPIError(true);
    setProgressNotes([]);
  };

  useEffect(() => {
    if (notesData && Array.isArray(notesData.body)) {
      if (isValidResponse(notesData?.statusCode)) {
        const sortedNotes = sortDataByOrder(sortByNewest);
        setProgressNotes(sortedNotes);
        setUserNativeIDs(getUserNativeIDsArray(sortedNotes));
      } else {
        handleAPIError();
      }
    }
  }, [notesData, isNotesLoading]);

  const handleEdit = (note: any) => {
    setSaveEnable(false);
    setNoteEditable(true);
    setIsEditable(false);
    setTextareaValue(note.note);
    setEditNoteId(note.aboNoteId);
  };

  const [
    deleteProgressNote,
    { isLoading: isLoadingDelete, isError: isErrorInDelete },
  ] = useDeleteProgressNoteMutation();

  const handleDeleteNote = async (noteId: number) => {
    const response: any = await deleteProgressNote({
      id: noteId,
    });

    if (!isValidResponse(response?.data?.statusCode)) {
      handleAPIError();
    }
  };

  //API call to create new note.
  const [
    createNotes,
    { isLoading: isNotesCreating, isError: isErrorInCreateNote },
  ] = useCreateProgressNotesMutation();

  const [editNotes, { isLoading: isNotesEditing, isError: isErrorInEditNote }] =
    useEditProgressNotesMutation();

  useEffect(() => {
    if (
      isErrorInFetchNotes ||
      isErrorInCreateNote ||
      isErrorInEditNote ||
      isErrorInDelete
    )
      handleAPIError();
  }, [
    isErrorInFetchNotes,
    isErrorInCreateNote,
    isErrorInEditNote,
    isErrorInDelete,
  ]);

  const handleClickSave = async () => {
    const payload = {
      performanceYear: selectedYear,
      title: "",
      note: textareaValue,
    };

    if (editNoteId) {
      const editNotesResponse: any = await editNotes({
        affiliateCode: userDetails.affiliateCode,
        aboNumber: userDetails.aboNumber,
        payload: { ...payload, aboNoteId: editNoteId },
      });
      setEditNoteId(null);
      if (!isValidResponse(editNotesResponse?.data?.statusCode)) {
        handleAPIError();
      }
    } else {
      const response: any = await createNotes({
        affiliateCode: userDetails.affiliateCode,
        aboNumber: userDetails.aboNumber,
        payload: payload,
      });

      if (!isValidResponse(response?.data?.statusCode)) {
        handleAPIError();
      }
    }

    setIsEditable(false);
    setNoteEditable(false);

    // if (response?.data?.statusCode === 200) {
    //   setIsEditable(false);
    // } else {
    // setIsEditable(false);
    // }
  };

  const textAreaChangeHandler = (editorData: any) => {
    const rowNote = editorData.replace(/\s*<\/?p>\s*/g, "");
    if (rowNote && rowNote.length > 0) {
      setSaveEnable(true);
    } else {
      setSaveEnable(false);
    }
    editorData === "<p><br></p>"
      ? setTextareaValue("")
      : setTextareaValue(editorData);
  };

  //   const getInitials = (name: string) => {
  //     const [firstName, lastName] = name.split(" ");
  //     return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  //   };


  const handleClickSortIcon = () => {
    setIsOpenPopup(!isOpenPopup);
  }

  const handleChangeSort = (sortItem: FilterDataType) => {
    if (sortItem?.id !== selectedSort?.id) {
      const sortedNotes = [...progressNotes].reverse();
      setProgressNotes(sortedNotes);
    }
    setSelectedSort(sortItem);
  }
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setIsOpenPopup(false));

  return (
    <section
      className={`notes_container ${styles.notes_container} ${isAPIError ? styles.full_width : ""}`}
    >
      {isNotesCreating ||
      isNotesLoading ||
      isNotesEditing ||
      isLoadingDelete ? (
        <ABOBackgroundLoader num={5} heading={t("notes")} />
      ) : isAPIError ? (
        <>
          <div className={styles.heading_area}>
            <div
              className={styles.title}
              data-testid="progress_notes_bg_heading_testID"
            >
              {t("notes")}
            </div>
          </div>
          <div data-testid="error_testID">
            <ErrorInPageScreen
              handleClickTryAgain={() => {
                setIsAPIError(false);
              }}
            />
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className={styles.heading_area}>
            <div
              className={styles.title}
              data-testid="progress_notes_bg_heading_testID"
            >
              {t("notes")}
            </div>
            <div className={styles.sort_section} onClick={handleClickSortIcon}>
              <div className={styles.sort_label}>
                {selectedSort?.id === 'newest_first' ? t("newest_first") : t("oldest_first")}
              </div>
              <img src={sortIcon} alt="" className={styles.sort_icon} />
              {isOpenPopup && (
                <div
                className={`dropdown_container ${styles.dropdown_container}`}
                >
                  <div ref={dropdownRef} className={`dropdown_menu ${styles.dropdown_menu}`}>
                    {map(NOTES_SORT_LIST, (filter_item) => (
                      <div
                        key={filter_item.id}
                        className={`${selectedSort?.id === filter_item?.id ? "dropdown_selected_item" : ""} ${styles.dropdown_item}`}
                        onClick={() => handleChangeSort(filter_item)}
                      >
                        {t(`${filter_item.id}`)}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.input_wrapper}>
            {isEditable ? (
              <>
                <div className={styles.formatter_wrapper}>
                  <FormatterBar
                    id="notes"
                    testId="notes_testID"
                    editorData={""}
                    handleDataChange={textAreaChangeHandler}
                    section={"notes"}
                    placeholder={t("add_comment")}
                    isContentEditable={true}
                    maxCharacter={1000}
                  />
                </div>
                <div className={styles.btn_wrapper}>
                  <Button
                    id="cancelButton"
                    onClick={handleClickCancel}
                    label={t("btnCancel")}
                    style="unfilled"
                  />
                  <Button
                    id="saveButton"
                    onClick={handleClickSave}
                    label={t("btnSave")}
                    style="filled"
                    isDisabled={!saveEnable || !textareaValue}
                  />
                </div>
              </>
            ) : (
              <input
                type="text"
                className={`input_text_area ${styles.input_text_area}`}
                placeholder={t("add_comment")}
                value={t("add_comment")}
                onClick={focusHandler}
                readOnly
                data-testid="textarea_testID"
              />
            )}
          </div>
          {map(progressNotes, (note) => (
            <div
              key={note.aboNoteId}
              className={`progress_note ${styles.progress_note}`}
            >
              <div className={`left_wrapper ${styles.left_wrapper}`}>
                <div className={`initials ${styles.initials}`}>
                  {getInitials(
                    getFullNameOfUserByNativeID(
                      note.audit.createdBy,
                      usersListByNativeId
                    )
                  )}
                </div>
              </div>
              <div className={`right_wrapper ${styles.right_wrapper}`}>
                <div className={styles.user_info}>
                  <div className={styles.user_name}>
                    {getFullNameOfUserByNativeID(
                      note.audit.createdBy,
                      usersListByNativeId
                    )}
                  </div>
                  <div className={`last_modified ${styles.last_modified}`}>
                    {/* {new Date(note.audit.updatedDate).toLocaleString()} */}
                    {formatDate(
                      note.audit.updatedDate,
                      countryCodeMappings[userAffiliateCode],
                      "dateTime"
                    )}
                    <span className={styles.edited_tag}>
                      {handleEditTag(
                        note.audit.updatedDate,
                        note.audit.createdDate
                      )}
                    </span>
                  </div>
                </div>
                <div className={styles.input_wrapper}>
                  {isNoteEditable && editNoteId == note.aboNoteId ? (
                    <>
                      <div
                        className={styles.formatter_wrapper}
                        data-testid="editor_testID"
                      >
                        <FormatterBar
                          id="notes"
                          testId="notes_testID"
                          editorData={textareaValue}
                          handleDataChange={textAreaChangeHandler}
                          section={"notes"}
                          placeholder={t("add_comment")}
                          isContentEditable={true}
                          maxCharacter={1000}
                        />
                      </div>
                      <div className={styles.btn_wrapper}>
                        <Button
                          id="cancelButton"
                          onClick={handleClickCancel}
                          label={t("btnCancel")}
                          style="unfilled"
                        />
                        <Button
                          id="saveButton"
                          onClick={handleClickSave}
                          label={t("btnSave")}
                          style="filled"
                          isDisabled={!saveEnable || !textareaValue}
                        />
                      </div>
                    </>
                  ) : (
                    <div>
                      <div
                        className={`note_content ${styles.note_content}`}
                        data-testid="all_notes_testID"
                      >
                        <p>
                          {note.note.length > 1000 ? (
                            <HTMLtoText
                              htmlContent={`${note.note.slice(0, 1000)}...`}
                            />
                          ) : (
                            <HTMLtoText htmlContent={note?.note} />
                          )}
                        </p>
                      </div>
                      <div className={`action_btns ${styles.action_btns}`}>
                        <span
                          className={styles.action_edit}
                          onClick={() => handleEdit(note)}
                          data-testid="edit_testID"
                        >
                          {t("edit")}
                        </span>
                        <span
                          className={styles.action_delete}
                          onClick={() => handleDeleteNote(note.aboNoteId)}
                        >
                          {t("delete")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </section>
  );
};

export default ProgressNotes;
