import React, { useEffect, useState } from "react";
import styles from "./AGCTile.module.scss";
import isEmpty from "lodash/isEmpty";
import GraphCard from "../GraphCard/GraphCard";
import Benchmark from "../Benchmark/Benchmark";
import InsightAI from "./InsightAI/InsightAI";
import YearChart from "./YearChart/YearChart";
import Icon from "../Icon/Icon";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { getTranslationKeyByCode } from "../../../utils/agcUtil";
import { CurrencyFormatter } from "../../../utils/currencyFormatter";

const AGCDetailsTile: React.FC<AGCProps> = ({ kpiName, title, label, description, updatedAGC, agcDetailsData, onSelectPeriod }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const { t } = useTranslation();
  const [activeKpis, setActiveKpis] = useState<string[]>([kpiName]);
  const [isActivartionRate, setIsActivationRate]= useState<boolean>(false)

  const handleSelectPeriod = (period: any) => {
    setSelectedPeriod(period);
    onSelectPeriod(period);
  };

  useEffect(() => {
    let selectedPeriod = 90;
    if (agcDetailsData?.kpiCode?.toLowerCase()?.includes("activationrate")) {
      if (agcDetailsData?.kpiCode?.toLowerCase()?.includes("30")) selectedPeriod = 30;
      else if (agcDetailsData?.kpiCode?.toLowerCase()?.includes("60")) selectedPeriod = 60;
      else if (agcDetailsData?.kpiCode?.toLowerCase()?.includes("90")) selectedPeriod = 90;
    }
    setSelectedPeriod(selectedPeriod);
  }, [agcDetailsData]);

  const closeTileHandler = (label: string, kpiName: string) => {
    // setAgcDetails(newDetails);

    const updatedActiveKpis = activeKpis.filter(activeKpi => activeKpi !== kpiName);
    setActiveKpis(updatedActiveKpis);

    if (updatedAGC) {
      updatedAGC([kpiName]); // Pass the kpiName to clear the corresponding pill
    }
  };

  useEffect(() => {
    if (kpiName.includes("activationRate:90|30|60")) {
      setIsActivationRate(true);
    } else {
      setIsActivationRate(false);
    }
  }, [kpiName]);  

  return (
    <div className={`agc_details_tile ${styles.agc_details_tile}`}>
      <div
        className={`agc_tile_wrapper ${styles.agc_tile_wrapper} ${styles.tile_closed}`}
      >
        <div className={styles.close_tile}>
          <Icon
            name="cross_icon"
            color={currentTheme === "light" ? "#2C2C2C" : "#D8D8D9"}
            onClick={() => { closeTileHandler(label, kpiName) }}
            className={styles.close_btn}
            size={17}
          />
        </div>
        {/* Left section */}
        <div className={styles.left_section}>
          <div className={styles.tile_header}>
            <div className={`title ${styles.title}`}>{t(`kpiTitles.${getTranslationKeyByCode(agcDetailsData?.kpiCode)}`, { numberOfDays: selectedPeriod })}</div>
            <div className={`desc ${styles.desc}`}>{t(`kpiDescription.${getTranslationKeyByCode(agcDetailsData?.kpiCode)}`, { numberOfDays: selectedPeriod })}</div>
          </div>
          <div className={styles.benchmark_section}>
            <div className={`benchmark_value ${styles.benchmark_value}`}>
              <span className={`yoy_value ${styles.yoy_value}`}>
                {CurrencyFormatter(agcDetailsData?.kpiCurrency, agcDetailsData?.kpiValue)}
              </span>
              <div className={`yoy ${styles.yoy}`}>
                <span
                  className={`${styles.change_in_value} ${agcDetailsData?.kpiVarianceValue >= 0 ? "rise" : "fall"}`}
                >
                  {agcDetailsData?.kpiVarianceValue >= 0 ? "▲" : "▼"}
                  {agcDetailsData?.kpiVarianceDataType === "currency" &&
                    agcDetailsData?.kpiCurrency === "USD" &&
                    "$"}
                  {Math.abs(agcDetailsData?.kpiVarianceValue)}
                  {agcDetailsData?.kpiVarianceDataType === "percent" && "%"}
                </span>
                {agcDetailsData?.kpiVarianceComparisonCriteria}
              </div>
            </div>
            {
              agcDetailsData?.benchmark &&
              <div className={styles.benchmark_bar}>
                <Benchmark
                  minValue={agcDetailsData?.benchmark?.start}
                  maxValue={agcDetailsData?.benchmark?.end}
                  midValue={agcDetailsData?.benchmark?.mid}
                  benchmarkValue={agcDetailsData?.kpiValue}
                  isPercentValue={false}
                  currency={
                    agcDetailsData?.kpiCurrency && agcDetailsData?.kpiCurrency === "USD" && "$"
                  }
                />
              </div>
            }
          </div>

          {/* <div className={styles.insight_section}>
            <InsightAI
              updateDate={'26/09/2024'}
              content={t("aiInsightDescription")}
              showDropdown={false}
              isDropdownEnabled={false}
            />
          </div> */}
        </div>
        {/* Right Section */}
        <div className={styles.right_section}>
          {agcDetailsData?.kpiCode?.includes("activationRate") ? (
            <div className={`activation_wrapper ${styles.activation_wrapper}`}>
              {[30, 60, 90].map((period) => (
                <div
                  key={period}
                  className={`rate_period ${styles.rate_period} ${selectedPeriod === period ? "selected_period" : ''}`}
                  onClick={() => handleSelectPeriod(period)}
                >
                  {period}
                </div>
              ))}
            </div>
          ) : null}
          <div className={styles.table_section}>
            {isEmpty(agcDetailsData?.detail?.chart?.chartDetail?.tableDetail) ? (
              <GraphCard lineChartData={agcDetailsData?.detail?.chart?.chartDetail?.lineChartDetail} activationPeriod={selectedPeriod} isActivartionRate={isActivartionRate} />
            ) : (
              <YearChart tableData={agcDetailsData?.detail?.chart?.chartDetail?.tableDetail} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AGCDetailsTile;
