import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Notes.module.scss";
import Button from "../../common/Button/Button";
import FormatterBar from "../../common/FormatterBar/FormatterBar";
import HTMLtoText from "../../common/HTMLToText/HTMLToText";
import { formatDate } from "../../../utils/dateFormatter";
import { countryCodeMappings } from "../../../constants/dataConstants";
import { getInitials } from "../../../utils/common";
import { UserDetails } from "../../../types/types";
import { getFullNameOfUserByNativeID } from "../../../utils/progressNotesUtil";
import TagDropdown from "../TagDropodown/TagDropdown";

interface NoteItemProps {
  note: {
    apNoteId: number;
    note: string;
    title: string;
    performanceYear: number;
    tag: string[];
    audit: {
      createdDate: string;
      createdBy: string;
      updatedDate: string;
      updatedBy: string;
    };
  };
  isNoteEditable: boolean;
  editNoteId: number | null;
  usersListByNativeId: UserDetails[] | null;
  userAffiliateCode: string;
  onEdit: (note: any) => void;
  onDelete: (noteId: number | null) => void;
  onSave: (id: number | null, textFil: string, tagFil: string[]) => void;
  onCancel: () => void;
  getTags?: string[];
  onChangeTags?: (tags: string[]) => void;
  isSourceAP: boolean;
  saveBtnDisabled: boolean;
}

const NoteItem: React.FC<NoteItemProps> = ({
  note,
  isNoteEditable,
  editNoteId,
  usersListByNativeId,
  userAffiliateCode,
  onEdit,
  onDelete,
  onSave,
  onCancel,
  getTags = [],
  onChangeTags,
  isSourceAP,
  saveBtnDisabled,
}) => {
  const { t } = useTranslation();
  const [saveEnable, setSaveEnable] = useState(saveBtnDisabled);
  const [textareaValue, setTextareaValue] = useState<string>(note.note);
  const [currentNoteTags, setCurrentNoteTags] = useState<string[]>(note.tag);
  const [updatedNote, setUpdatedNote] = useState(note);

  useEffect(() => {
    setUpdatedNote(note);
  }, [note, textareaValue, currentNoteTags]);
  const handleTextAreaChange = (editorData: string) => {
    const rowNote = editorData.replace(/\s*<\/?p>\s*/g, "");
    setSaveEnable(!!(rowNote && rowNote.length > 0));
    setTextareaValue(editorData === "<p><br></p>" ? "" : editorData);
  };

  const handleChangeTags = (updatedTags: string[]) => {
    setSaveEnable(true);
    setCurrentNoteTags(updatedTags);
    if (onChangeTags) onChangeTags(updatedTags);
  };

  return (
    <div
      key={note.apNoteId}
      className={`progress_note ${styles.notes_wrapper}`}
    >
      <div className={`left_wrapper ${styles.left_wrapper}`}>
        <div className={`initials ${styles.initials}`}>
          {getInitials(
            getFullNameOfUserByNativeID(
              note.audit.createdBy,
              usersListByNativeId
            )
          )}
        </div>
      </div>
      <div className={`right_wrapper ${styles.right_wrapper}`}>
        <div className={styles.user_info}>
          <div className={styles.user_name}>
            {getFullNameOfUserByNativeID(
              note.audit.createdBy,
              usersListByNativeId
            )}
          </div>
          <div className={`last_modified ${styles.last_modified}`}>
            {formatDate(
              note.audit.updatedDate,
              countryCodeMappings[userAffiliateCode],
              "dateTime"
            )}
            <span className={styles.edited_tag}>
              {new Date(note.audit.updatedDate)?.toLocaleString() ===
              new Date(note.audit.createdDate)?.toLocaleString()
                ? ""
                : t(isSourceAP ? "AP_info.edited" : "edited")}
            </span>
          </div>
        </div>
        <div className={styles.input_wrapper}>
          {isNoteEditable && editNoteId === note.apNoteId ? (
            <>
              <div className={styles.tags_container}>
                <TagDropdown
                  getTags={getTags}
                  selectedTags={note.tag}
                  onChangeTags={handleChangeTags}
                />
              </div>
              <div className={styles.formatter_wrapper}>
                <FormatterBar
                  id="notes"
                  testId="notes_testID"
                  editorData={updatedNote.note}
                  handleDataChange={handleTextAreaChange}
                  section={"notes"}
                  placeholder={t(
                    isSourceAP ? "AP_info.add_comment" : "add_comment"
                  )}
                  isContentEditable={true}
                  maxCharacter={1000}
                />
              </div>
              <div className={styles.btn_wrapper}>
                <Button
                  id="cancelButton"
                  onClick={() => {
                    onCancel();
                    setSaveEnable(false);
                  }}
                  label={t(isSourceAP ? "AP_info.btnCancel" : "btnCancel")}
                  style="unfilled"
                />
                <Button
                  id="saveButton"
                  onClick={() => {
                    onSave(note.apNoteId, textareaValue, currentNoteTags);
                    setSaveEnable(false);
                  }}
                  label={t(isSourceAP ? "AP_info.btnSave" : "btnSave")}
                  style="filled"
                  isDisabled={
                    !saveEnable || !textareaValue
                  }
                />
              </div>
            </>
          ) : (
            <div>
              <div className={`note_content ${styles.note_content}`}>
                {note.note.length > 1000 ? (
                  <HTMLtoText htmlContent={`${note.note.slice(0, 1000)}...`} />
                ) : (
                  <HTMLtoText htmlContent={note.note} />
                )}
              </div>
              {note.tag.length > 0 && (
                <div
                  className={`tab_tag_container ${styles.tab_tag_container}`}
                >
                  {note.tag.map((tag, index) => (
                    <div key={index}>
                      <span className={`tab_label ${styles.tab_label}`}>
                        {tag}
                      </span>
                    </div>
                  ))}
                </div>
              )}
              <div className={`action_btns ${styles.action_btns}`}>
                <span
                  className={styles.action_edit}
                  onClick={() => onEdit(note)}
                >
                  {t(isSourceAP ? "AP_info.edit" : "edit")}
                </span>
                <span
                  className={styles.action_delete}
                  onClick={() => {
                    onDelete(note.apNoteId);
                  }}
                >
                  {t(isSourceAP ? "AP_info.delete" : "delete")}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoteItem;
