import { isNull } from "lodash";
import {
  SortOrder
} from "../types/types";

// Get initials of passed string
export const getInitials = (fullName: String) => {
  const words = fullName?.split(" ");
  let initials = "";
  for (let i = 0; i < words.length && i < 3; i++) {
    initials += words[i].replace(/[^a-zA-Z ]/g, "").charAt(0);
  }
  return initials;
};

// convert utc date to format like [12-04-22 3:30pm]
export const formatDate = (date: string) => {
  // Extracting components from the date
  const newDate = new Date(date);
  const day = newDate.getDate()?.toString()?.padStart(2, "0");
  const month = (newDate.getMonth() + 1)?.toString()?.padStart(2, "0");
  const year = newDate?.getFullYear()?.toString()?.slice(-2);

  const minutes = newDate?.getMinutes()?.toString().padStart(2, "0");
  const hours = newDate.getHours() % 12 || 12; // Convert to 12-hour format
  const ampm = newDate.getHours() >= 12 ? "pm" : "am";

  // Constructing the formatted string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;

  return formattedDate;
};

function compareValues(valueA: string | number, valueB: string | number): number {
  // Handle numbers
  if (typeof valueA === 'number' && typeof valueB === 'number') {
    return valueA - valueB;
  }

  const stringA = String(valueA)?.toLowerCase();
  const stringB = String(valueB)?.toLowerCase();

  if (stringA === stringB) {
    return 0;
  }

  return stringA < stringB ? -1 : 1;
}

export const sortTableData = <T>(
  data: T[],
  column: keyof T,
  order: SortOrder = "desc",
): T[] => {

  if (!["asc", "desc"].includes(order)) {
    console.log("Order parameter must be 'asc' or 'desc'");
    return data;
  }

  if (!data[0]?.hasOwnProperty(column)) {
    console.error("Column not found in data");
  }

  return data?.sort((a, b) => {
    const valueA: any = a[column];
    const valueB: any = b[column];


    // Use the comparison function to compare values
    if (order === "asc") {
      return compareValues(valueA, valueB);
    } else {
      return compareValues(valueB, valueA); // Reverse order for descending
    }

  });
}

function flattenObject(obj: any, keysToFind: string[]): any {
  let results: any = null;

  function findHelper(obj: any) {
    for (const key in obj) {
      if (keysToFind?.includes(key)) {
        const result = { [key]: obj[key] };
        results = { ...results, ...result };
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        findHelper(obj[key]);
      }
    }
  }

  findHelper(obj);
  return results;
}

export function findKeysInArray(arr: any[], keysToFind: string[]): any[] {
  const newArr: any = [];
  arr.length > 0 && arr?.map((obj: any) => {
    if (!isNull(obj)) {
      const flattenObj = flattenObject(obj, keysToFind);
      newArr?.push(flattenObj);
    }
  })
  return newArr;
}

//--- Account Table Utility function

export function getNestedValue<T>(obj: T, path: string): any {
  return path?.split('.')?.reduce((acc: any, part: string) => acc && acc[part], obj);
}

export function sortAccountTableData<T>(data: T[], path: string, order: SortOrder): T[] {
  // Check if the sort order is 'nosort'
  if (order === "nosort") {
    return data;
  }

  // Check if the column exists in the first row of the data
  if (data.length > 0 && getNestedValue(data[0], path) === undefined) {
    console.warn(`Column path "${path}" does not exist in the data.`);
    return data;
  }

  let arrayForSort = [...data];
  return arrayForSort.sort((a, b) => {
    const valueA: any = getNestedValue(a, path);
    const valueB: any = getNestedValue(b, path);

    if (order === "asc") {
      return compareValues(valueA, valueB);
    } else {
      return compareValues(valueB, valueA); // Reverse order for descending
    }
  });
}

// US phone number format 
export const formatPhoneNumber = (phoneNumber: string | number | undefined, phoneId: string | number | undefined, phoneArea: string | number | undefined, phoneExtension: string | number | undefined) => {
  // If empty string - return 
  if (!phoneNumber) {
    return phoneNumber;
  }
  // Remove all non-digit characters
  const cleaned: any = ('' + phoneArea + phoneNumber).replace(/\D/g, '');
  // Check if the input is of correct length
  if (cleaned?.length !== 10) {
    console.error("Invalid phone number length. Expected 10 digits.");
  }
  // Format the number
  const formattedPhoneNumber: any = `${phoneId ? "+" + phoneId : ''}${phoneId == "1" && cleaned?.length === 10 ? ` ${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}` :
    `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6)}`}`; // Add space between sliced if needed-AMW-460

  const cleanedExtension = ('' + phoneExtension).replace(/\D/g, '');
  const formattedPhoneNumberWithExtension = formattedPhoneNumber + (cleanedExtension ? ', ' + cleanedExtension : '');

  return formattedPhoneNumberWithExtension;
}

export const formatUSPostalCode = (postalCode: string | number | undefined, primaryAddressCountry: string | undefined) => {
  // If empty string - return 
  if (!postalCode) {
    return postalCode || "";
  }
  // Remove all non-digit characters
  const cleaned: any = ('' + postalCode).replace(/\D/g, '');
  // Check if the input is of correct length
  if (cleaned?.length !== 9) {
    console.error("Invalid postal code length. Expected 9 digits.");
  }
  // Format the postal code
  const formatted = `${cleaned?.length > 5 && primaryAddressCountry?.toLowerCase() === "us" ? (cleaned?.slice(0, 5) + "-" + cleaned?.slice(5)) : cleaned}`;
  return formatted;
}

// Determine translation keys based on screenName
export const translateKey = (key: string, screenName?: string) => {
  switch (screenName) {
    case "apInformation":
      return `AP_info.${key}`;
    default:
      return key;
  }
};
