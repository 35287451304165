import React from "react";
import LineGraph from "../Graph/Graph";
import styles from "./GraphCard.module.scss";
import Icon from "../Icon/Icon";
import dottedLineLight from "../../../assets/images/LightTheme/dottedLight.svg";
import planeLineDark from "../../../assets/images/DarkTheme/lineDark.svg";
import dottedLineDark from "../../../assets/images/DarkTheme/dottedDark.svg";
import planeLineLight from "../../../assets/images/LightTheme/lineLight.svg";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";

interface CardProps {
  lineChartData: LineChartData;
  barChartData?: any;
  activationPeriod?: any;
  isActivartionRate?: boolean;
}

const GraphCard: React.FC<CardProps> = ({ lineChartData, barChartData, activationPeriod, isActivartionRate }) => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const dottedLine = currentTheme === "light" ? dottedLineLight : dottedLineDark;
  const planeLine = currentTheme === "light" ? planeLineLight : planeLineDark;
  const { t } = useTranslation();
  const currentYearData = lineChartData?.body?.lines[0];
  const lastDatapoint = currentYearData?.datapoint[currentYearData.datapoint.length - 1];
  const currentValue = lastDatapoint ? parseFloat(lastDatapoint.yValue) : null;
  const currentMonth = lastDatapoint ? lastDatapoint.xValue : null;
  const currentYear = currentYearData?.lineName;
  const prevYearData = lineChartData?.body?.lines[1];
  const prevYear = prevYearData?.lineName;
  const prevYearValue = prevYearData?.datapoint.find((point: any) => point.xValue === currentMonth)?.yValue;
  const prevYearValueNum = prevYearValue ? parseFloat(prevYearValue) : null;
  const percentageChange =
  prevYearValueNum !== null && currentValue === 0 ? 0 :
    prevYearValueNum !== null && currentValue !== null 
      ? ((currentValue - prevYearValueNum) / prevYearValueNum) * 100
      : null;

  if (!lineChartData?.body?.lines?.length) {
    return (
      <div className={`graph_card_container ${styles.graph_card_container}`}>
        No data available
      </div>
    );
  }
  return (
    <div className={`graph_card_container ${styles.graph_card_container}`}>
      {/* Header Section */}
      <div className={`card_header ${styles.card_header}`}>
        <div className={styles.legend_wrapper}>
          <div className={styles.legend}>
            <div className={styles.line_type}>
              <img src={dottedLine} alt="line" />
            </div>
            <div>{prevYear}</div>
          </div>
          <div className={styles.legend}>
            <div className={styles.line_type}>
              <img src={planeLine} alt="line" />
            </div>
            <div>{currentYear}</div>
          </div>
        </div>
      </div>

      {/* Graph Section */}
      <div className={`graph_wrapper ${styles.graph_wrapper}`}>
        <LineGraph lineChartData={lineChartData} barChartData={barChartData} activationPeriod={activationPeriod} isActivartionRate={isActivartionRate} />
      </div>

      {/* Footer Section */}
      <div className={`card_footer ${styles.card_footer}`}>
        <div className={styles.month_data}>
          <div className={`current_month_year ${styles.current_month_year}`}>
            {currentYear} {currentMonth}
          </div>
          <div className={`data_value ${styles.data_value}`}>
            {currentValue !== null ? `${currentValue}` : "N/A"}
          </div>
          <div
            className={`value_info_wrapper ${styles.value_info_wrapper} ${
              percentageChange !== null && percentageChange >= 0 ? "raised_change" : "fall_change"
            }`}
          >
            <span className={`percentage_value_change ${styles.percentage_value_change}`}>
              {percentageChange !== null ? (percentageChange > 0 ? "▲" : percentageChange === 0 ? "▲" : "▼") : ""}{" "}
              {percentageChange !== null ? `${percentageChange.toFixed(0)}%` : "N/A"}
            </span>
            <span className={`value_diff ${styles.value_diff}`}>
              vs {prevYear} {currentMonth}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphCard;
