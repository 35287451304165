import React, { useEffect, useState, useCallback } from "react";
import styles from "./SearchBar.module.scss";
// import SearchLight from "../../../assets/images/seach_icon_l.svg";
// import SearchDark from "../../../assets/images/search_icon_d.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/types";
import { useTranslation } from "react-i18next";
import UserProfileDropdown from "../UserProfile/UserProfileDropdown";
import { useSearchABOMutation } from '../../../redux/services/globalSearchAPI';
import { DataItem } from "../../../types/types.d";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Icon from "../Icon/Icon";

interface SearchBarProps {
  placeholder: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholder }) => {
  const history = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [searchABO, { isLoading: isLoadingSearchABO }] = useSearchABOMutation();
  const [searchData, setSearchData] = useState<DataItem[]>([]);
  const currentTheme = useSelector((state: RootState) => state.theme.currentTheme);
  // const { t } = useTranslation();
  // const searchIcon = currentTheme === "light" ? SearchLight : SearchDark;

  const searchDebounceABO = useCallback(
    debounce(async (term) => {
      if (term.trim().length > 2) {
        const response = await searchABO({
          payload: { keyword: term?.trim() },
        });
        if (response && 'data' in response && response.data) {
          setSearchData(response.data.body);

		  const searchData = {
			searchKeyword:term, 
			searchResults:response.data.body.length,
			searchType: "entered"
		};
		window?.heap?.track('Platform: Search: View: Results', searchData);
        }
      }
    }, 600),
    []
  );
  const handleSearchABO = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    searchDebounceABO(term);
  };
  const handleSearchInput = async () => {
    searchDebounceABO(searchTerm);

	const searchClick={
		"click_section":(history.pathname === "/profile") ? "personal_info" : "my_account",
		"click_category":"header",
		"click_action" : `button<search>`
		}
	
		window?.heap?.track(searchClick);
  };
  const handleSearchTerm =() => {
    setSearchTerm("");
  }

  useEffect(() => {
    setSearchTerm("");
  }, [navigate]);

  return (
    <div className={`search_bar_container ${styles.search_bar_container}`}>
      <Icon
          name="search"
          size={24}
          color={currentTheme === 'light' ? '#2c2c2c' : '#AEAEB2'}
          onClick={handleSearchInput}
          className={styles.search_icon}
        />
      <input
        className={`search_input ${styles.search_input}`}
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleSearchABO}
      />
      {searchData && searchTerm?.trim()?.length > 2 && (
        <div className={styles.search_dropdown_container}>
          <UserProfileDropdown
            searchTerm={searchTerm}
            handleSearchTerm={handleSearchTerm}
            data={searchData}
            isLoadingSearchABO={isLoadingSearchABO}
          />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
