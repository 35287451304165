import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppSelector } from "../../../redux/hooks";
import styles from "./Graph.module.scss";
import "../../../App.scss";

interface LineAndBarChartProps {
  lineChartData: LineChartData;
  barChartData: any[];
  activationPeriod?: any;
  isActivartionRate?: boolean;
}

const LineGraph: React.FC<LineAndBarChartProps> = ({
  lineChartData,
  barChartData,
  activationPeriod,
  isActivartionRate,
}) => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const [showLineChart, setShowLineChart] = useState(true);
  const selectedPeriod = activationPeriod ?? 90;
  const currentYearData = lineChartData?.body?.lines[0];
  const lastDatapoint = currentYearData?.datapoint[currentYearData?.datapoint?.length - 1];
  const currentValue = lastDatapoint ? parseFloat(lastDatapoint?.yValue) : null;
  const currentMonth = lastDatapoint ? lastDatapoint?.xValue : undefined;
  useEffect(() => {
    if (activationPeriod) {
      setShowLineChart(true);
    }
  }, [activationPeriod]);

  const getChartData = () => {
    return lineChartData?.body?.lines?.map((line, index) => {
      const chartData = line?.datapoint.map((point) => Math.ceil(parseFloat(point.yValue) || 0));
      return {
        name: line?.lineName,
        data: chartData,
        dashArray: index === 0 ? 0 : 8,
      };
    });
  };
  const formattedLineChartData = getChartData();
  const xAxisCategories = isActivartionRate === false ? lineChartData?.xAxis.map((x) => x.dataValue) :
    lineChartData?.xAxis
    .map((x) => x.dataValue)
  if (currentMonth && !xAxisCategories.includes(currentMonth)) {
    xAxisCategories.push(currentMonth);
  }
  const xAxisLabelColors = Array.from(new Set(xAxisCategories)).map((category) =>
    category === currentMonth ?
      (currentTheme === "dark" ? "#99B2F5" : "#5969D6") :
      (currentTheme === "dark" ? "#AEAEB2" : "#707070")
  );
  const yAxisDataType = lineChartData?.yAxis?.[0]?.dataType || "number"; 
  const formatYAxisLabel = (value: number | undefined): string => {
    if (value === undefined || value === null) {
      return "N/A";
    }
    switch (yAxisDataType) {
      case "percentage":
        return `${value}%`;
      case "currency":
        return `$${value}k`;
      case "number":
      case "string":
        return value.toString();
      default:
        return value.toString();
    }
  };

  const chartOptions: ApexOptions = {
    chart: {
      height: 200,
      type: "line",
      toolbar: {
        show: false,
      },
      background: "transparent",
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 100,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    colors: currentTheme === "dark"
      ? ["#99B2F5", "#D8D8D9"]
      : ["#5969D6", "#5E5E5E"],
    stroke: {
      curve: "smooth",
      width: [2, 2],
      dashArray: [0, 8],
    },
    markers: {
      size: 4,
      colors:
        currentTheme === "dark"
          ? ["#99B2F5", "#D8D8D9"]
          : ["#5969D6", "#5E5E5E"],
      strokeColors:
        currentTheme === "dark"
          ? ["#99B2F5", "#D8D8D9"]
          : ["#5969D6", "#5E5E5E"],
      strokeWidth: 0,
    },
    xaxis: {
      categories: xAxisCategories,
      labels: {
        style: {
          colors: xAxisLabelColors,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: {
        text: showLineChart ? "" : "Revenue ($K)",
        style: {
          color: currentTheme === "dark" ? "#AEAEB2" : "#707070",
        },
      },
      // stacked: true,
      labels: {
        style: {
          colors: currentTheme === "dark" ? "#AEAEB2" : "#707070",
          fontSize: "12px",
        },
        formatter: (value: number): string => formatYAxisLabel(value),
      },
    },
    grid: {
      borderColor: currentTheme === "dark" ? "#636366" : "#E4E4E4",
    },
    dataLabels: {
      enabled: false,
    },
    annotations: {
      xaxis: [
        {
          x: currentMonth,
          borderColor: currentTheme === "dark" ? "#D6D6D6" : "#E4E4E4",
          strokeDashArray: 5,
          label: {
            style: {
              color: "#fff",
              background: "red",
            },
            text: "",
          },
        },
      ],
      points: [
        {
          x: currentMonth,
          y: currentValue,
          marker: {
            size: 10,
            fillColor: currentTheme === "dark" ? "#99B2F5" : "#5969D6",
            strokeColor:
              currentTheme === "dark"
                ? "rgba(153, 178, 245, 0.36)"
                : "rgba(89, 105, 214, 0.36)",
            strokeWidth: 8,
          },
          label: {
            text: "",
            style: {
              color: currentTheme === "dark" ? "#99B2F5" : "#5969D6",
              fontSize: "12px",
              background: "transparent",
            },
          },
        },
      ],
    },
    tooltip: {
      enabled: true,
      theme: currentTheme,
      style: {
          fontSize: "14px",
          fontFamily: "Arial, sans-serif",
      },
      fillSeriesColor: true,
      y: {
          formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
              const seriesName = w.globals.seriesNames[seriesIndex];
              const year = seriesName;
              if(value){
                switch (yAxisDataType) {
                  case "percentage":
                      return `${year}: ${value}%`;
                  case "currency":
                      return `${year}: $${value}k`;
                  case "number":
                      return `${year}: ${value}`;
                  default:
                      return `${year}: ${value}`;
              }
              }else{
                return "k"
              }
              
          },
          title: {
              formatter: (seriesName) => `${seriesName}: `,
          },
      },
      marker: {
          show: true,
      },
      custom: function ({value, series, seriesIndex, dataPointIndex, w }) {
        const month = xAxisCategories[dataPointIndex % 12];
        const yearData = lineChartData.body.lines[seriesIndex]?.lineName;
        return `<div style="
          padding: 12px; 
          background: ${currentTheme === 'dark' ? '#99B2F5' : '#5969D6'};
          color: ${currentTheme === 'dark' ? '#000000' : '#F4F4F4'};
          border-radius: 12px;
          min-height: 60px;
          width: auto;
          display:flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
          font-size: 14px;
          line-height: 18px;
          ">
            <div style="margin-bottom: 4px;">${yearData} ${month}</div>
            <div style="">value: $${value}</div>
        </div>`;
      },
      hideEmptySeries: true,
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className={styles.chart_container} data-theme={currentTheme}>
      <Chart
        options={chartOptions}
        series={showLineChart ? formattedLineChartData : barChartData}
        type={showLineChart ? "line" : "bar"}
        height={200}
      />
    </div>
  );
};

export default LineGraph;
