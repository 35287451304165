import React, { useState } from "react";
import styles from "./BusinessPerformance.module.scss";
import ViewPlaybookLight from "../../../../assets/images/LightTheme/view_playbook.svg";
import ViewPlaybookDark from "../../../../assets/images/DarkTheme/view_playbook.svg";
import { useAppSelector } from "../../../../redux/hooks";
import ExpandAIInsightLight from "../../../../assets/images/LightTheme/expand_ai_insight.svg";
import ExpandAIInsightDark from "../../../../assets/images/DarkTheme/expand_ai_insight.svg";
import Summary from "./Summary/Summary";
import AGCDetailsTile from "../../../common/AGCDetailsTile/AGCTile";
import KPIsPill from "./KPIsPillSection/KPIsPill";
import SeperatorLineDark from "../../../../assets/images/DarkTheme/seperator_line.svg";
import SeperatorLineLight from "../../../../assets/images/LightTheme/seperator_line.svg";
import { isEmpty } from "lodash";
import AIInsight from "../../../common/AGCDetailsTile/InsightAI/InsightAI";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useTranslation } from "react-i18next";

const BusinessPerformance: React.FC = () => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const { t } = useTranslation();
  const playbookImage = currentTheme === "light" ? ViewPlaybookLight : ViewPlaybookDark;
  const SeperatorIcon = currentTheme === "dark" ? SeperatorLineDark : SeperatorLineLight;
  const [isSummarySelected, setIsSummarySelected] = useState<boolean>(true);
  const [selectedKPIs, setSelectedKPIs] = useState<string[]>([]);
  const closingMonth: any =
    useSelector((state: RootState) => state?.businessPerformanceClosingMonth?.businessPerformanceClosingMonth) || '';

  const selectDeselctFunctionality = (clickedKpis: string) => {
    const isAvailable = selectedKPIs.includes(clickedKpis);
    if (isAvailable) {
      const updatedArray = selectedKPIs.filter(name => name !== clickedKpis);
      setSelectedKPIs(updatedArray);
      if (isEmpty(updatedArray)) {
        setIsSummarySelected(true);
      }
    } else {
      setSelectedKPIs([...selectedKPIs, clickedKpis]);
      setIsSummarySelected(false);
    }
  };
  const selectPill = (name: string) => {
    selectDeselctFunctionality(name);
  };
  const selectSummaryPill = () => {
    setIsSummarySelected(true);
    setSelectedKPIs([]);
  };
  const handleTileClose = (updatedData: AGCProps[]) => {
    const updatedKpiNames = updatedData.map(kpi => (typeof kpi === 'string' ? kpi : kpi.kpiName));
    const newSelectedKPIs = selectedKPIs.filter(kpi => !updatedKpiNames.includes(kpi));
    setSelectedKPIs(newSelectedKPIs);
    if (newSelectedKPIs.length === 0) {
      setIsSummarySelected(true);
    }
  };

  const aiInsightContent = (
    <div className={`insight_content_wrapper ${styles.insight_content_wrapper}`}>
      <p>
        Income and productivity show steady increases, with room for further gains.
        Group size and contributor engagement are growing, indicating healthy recruitment
        and retention efforts. Leadership development is progressing, but potential exists
        to enhance foundational leadership roles further.
      </p>
      <ul>
        <li>
          <span className={`highlight ${styles.highlight}`}>Contributor Engagement:</span> Monthly Average Contributors up 8% YoY to 2,412—positive, but activation efforts (15% YoY increase to 70%) show room for further engagement.
        </li>
        <li>
          <span className={`highlight ${styles.highlight}`}>Leadership Development:</span> Repeat Bronze Structure ABO Count rose by 8 to 40—showing growth in foundational leadership, with potential to strengthen further.
        </li>
        <li>
          <span className={`highlight ${styles.highlight}`}>Retention:</span> Founders Platinum Requalification Rate up 18% YoY to 27%—strong retention, but with potential to aim higher.
        </li>
      </ul>
    </div>
  );

  return (
    <div className={`business_performance ${styles.business_performance}`}>
      <div>
        <div className={`header ${styles.header}`}>
          <span className={`title ${styles.title}`}>{t("businessPerformance")}</span>
          <a href="#view-playbook" className={`playbook_link ${styles.playbook_link}`}>
            <img
              src={playbookImage}
              alt="Playbook icon"
              className={`playbook_icon ${styles.playbook_icon}`}
            />
            <span className={`playbook_text ${styles.playbook_text}`}>
              {t("viewPlaybook")}
            </span>
          </a>
        </div>

        <div className={`last_closed_month_wrapper ${styles.last_closed_month_wrapper}`}>
          <span className={`last_closed_month ${styles.last_closed_month}`}>
            {t("Last_closed_month")} {closingMonth}
          </span>
        </div>

        {/* AIInsight component usage with dropdown */}
        {/* <AIInsight
          updateDate="08/19/2024"
          content={aiInsightContent}
          showDropdown={true}
          isDropdownEnabled={false}
          flowerIconSize={24}
          infoIconSize={12}
          title={"ai_summary"}
          titleSize={16}
        /> */}

      </div>
    </div>
  );
};

export default BusinessPerformance;
