import React from "react";
import styles from "./YearChart.module.scss";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { CurrencyFormatter } from "../../../../utils/currencyFormatter";

interface YearChartProps {
  tableData: any;
}
const YearChart: React.FC<YearChartProps> = ({ tableData }) => {
  const { t } = useTranslation();
  return (
    <div className={`annual_tbl_wrapper ${styles.annual_tbl_wrapper}`}>
      <table className={styles.annual_table}>
        <thead className={styles.tbl_head_wrapper}>
          <tr className={`tbl_head ${styles.tbl_head}`}>
            {map(tableData?.header?.row, (row) => {
              const kpiKey = row?.kpiValue.replace(/\s+/g, ''); // Remove spaces for key
              return <th key={kpiKey}>{t(kpiKey) || row?.kpiValue}</th>;
            })}
          </tr>
        </thead>
        <tbody className={styles.tbl_body}>
          {map(tableData?.body?.rows, (row, index: any) => (
            <tr
              key={index}
              className={`tbl_row ${styles.tbl_row} ${index % 2 === 0 ? `even_row ${styles.even_row}` : `odd_row ${styles.odd_row}`}`}
            >
              <td>{row?.[0]?.kpiValue}</td>
              <td
                className={`${
                  row?.[1]?.kpiValue === 0
                    ? styles.yoy_value
                    : row?.[1]?.kpiValue >= 0
                      ? `positive ${styles.positive}`
                      : `negative ${styles.negative}`
                }`}
              >
                {row?.[1]?.kpiValue === 0 ||
                row?.[1]?.kpiValue === undefined ||
                row?.[1]?.kpiValue === null
                  ? "---"
                  : row?.[1]?.kpiValue >= 0
                    ? `▲ ${row?.[1]?.kpiDataType === "currency" ? "$" : ""}${row?.[1]?.kpiValue}${row?.[1]?.kpiDataType === "percent" ? "%" : ""}`
                    : `▼ ${row?.[1]?.kpiDataType === "currency" ? "$" : ""}${Math.abs(row?.[1]?.kpiValue)}${row?.[1]?.kpiDataType === "percent" ? "%" : ""}`}
              </td>
              <td>
                {row?.[2]?.kpiDataType === "currency"}
                {(row?.[2]?.kpiValue !== undefined && row?.[2]?.kpiValue !== null) ? CurrencyFormatter("USD", row?.[2]?.kpiValue) : "---"}
                {row?.[2]?.kpiDataType === "percent" && "%"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default YearChart;
